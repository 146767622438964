.markdown p:last-child {
    margin-bottom: 0;
}

/* .markdown p strong:first-child {
    display: block;
} */

.markdown ul,
.markdown ol {
    padding-left: 1.2em;
}

.markdown p,
.markdown ul,
.markdown ol { 
    margin: 0 0 1em;
}

.markdown p:last-child,
.markdown ul:last-child,
.markdown ol:last-child { 
    margin: 0 0 0;
}

.markdown blockquote {
    padding: 1rem;
    margin: 1em 0;
    background: #ececec;
}

.markdown img {
    max-width: 100%;
}

.markdown hr {
    border: 0;
    border-bottom: 2px solid black;
}

.markdown table {
    display: block;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;
}

.markdown table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
}

.markdown table th {
    font-weight: 600;
}

.markdown table td,
.markdown table th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
}
